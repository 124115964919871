import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import WeightTable from '../../../components/WeightTable/WeightTable';
import RebarTable from '../../../components/RebarTable/RebarTable';
import LongRebarTable from '../../../components/LongRebarTable/LongRebarTable';

import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import useRebarInspection from '../../../hooks/useRebarInspection';

import { exportToExcel } from '../../../services/excelExport';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
    theoreticalWeightInfo,
    rebarTableInfo,
    placementTableInfo,
    longRebarTableInfo,
} from '../../../infoContents'
import InfoNote from '../../../components/InfoNote/InfoNote';

import { useTranslation } from 'react-i18next';
import DetailedRebarTable from '../../../components/RebarTable/DetailedRebarTable';
import CheckboxList from '../../../components/CheckboxList/CheckboxList';

import { TbTableOff, TbTableDown, TbTable } from "react-icons/tb";

const RebarInspection = (props) => {

    const { i18n, t } = useTranslation();

    const {
        rebars,
        weights,
        longRebars, // obsolete
        selectedLayoutElements,
        setSelectedLayoutElements,
        setSelectedExplanations,
        setSelectedDiameters,
        lengthInterval,
        setLengthInterval
    } = useRebarInspection(props);

    const longRebarsExist = longRebars.length > 0;

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState(<div></div>);
    const [expanded, setExpanded] = useState([]);
    const [showDetailedTable, setShowDetailedTable] = useState(false);

    const excelData = rebars.map((rebar) => {
        return {
            [t("Donatı Kodu")]: rebar.code,
            [t("Adet")]: rebar.number,
            [t("Uzunluk") + " (cm)"]: rebar["length"],
            [t("Çap") + " (mm)"]: rebar.diameter,
            [t("Poz")]: rebar.poz,
            [t("Kat")]: rebar.floor,
            [t("Eleman")]: rebar.element,
            [t("Eleman Kodu")]: rebar.elementCode,
            [t("Açıklama")]: rebar.explanation
        }
    });

    const filters = (
        <Accordion defaultActiveKey="layoutPlan" alwaysOpen={true} >
            <Accordion.Item eventKey="diameters">
                <Accordion.Header>
                    <span className='fw-bold'>
                        {t("Çaplar")}
                    </span>
                </Accordion.Header>
                <Accordion.Body>
                    <Container className="border py-3" style={{ maxHeight: "30vh", overflowY: "auto" }}>
                        <CheckboxList
                            items={
                                props.diameters.map((item) => (
                                    { "label": `Φ${item}`, "value": item }
                                ))}
                            onCheckedItemsChange={(selected) => setSelectedDiameters(selected)}
                            singleSelection={false}
                            emptySelection={true}
                            showAllLabel={false}
                            showDeselectLabel={true}
                        />
                    </Container>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="lengthInterval">
                <Accordion.Header>
                    <span className='fw-bold'>
                        {t("Uzunluk Aralığı")}
                    </span>
                </Accordion.Header>
                <Accordion.Body>
                    {/* Set length interval */}
                    <Form.Group className="mb-3">
                        <Row>
                            <Col xs={5}>
                                <Form.Control
                                    type="number"
                                    min={0}
                                    placeholder={t("Min")}
                                    onChange={(e) => setLengthInterval([e.target.value, lengthInterval[1]])}
                                />
                            </Col>
                            <Col xs={2} className="text-center d-flex align-items-center justify-content-center">
                                &minus;
                            </Col>
                            <Col xs={5}>
                                <Form.Control
                                    type="number"
                                    placeholder={t("Max")}
                                    onChange={(e) => setLengthInterval([lengthInterval[0], e.target.value])}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="explanations">
                <Accordion.Header>
                    <span className='fw-bold'>
                        {t("Açıklama")}
                    </span>
                </Accordion.Header>
                <Accordion.Body>
                    <Container className="border py-3" style={{ maxHeight: "30vh", overflowY: "auto" }}>
                        <CheckboxList
                            items={
                                props.explanations.map((item) => (
                                    { "label": item, "value": item }
                                ))}
                            onCheckedItemsChange={(selected) => setSelectedExplanations(selected)}
                            singleSelection={false}
                            emptySelection={true}
                            showAllLabel={false}
                            showDeselectLabel={true}
                        />
                    </Container>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="layoutPlan" >
                <Accordion.Header>
                    <span className='fw-bold'>
                        {t("Yerleşim Planı")}
                    </span>
                    {/* <InfoNote content={placementTableInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /> */}
                </Accordion.Header>
                <Accordion.Body>
                    <CheckboxTree
                        nodes={props.nodes[0].children}
                        showNodeIcon={false}
                        checked={selectedLayoutElements}
                        expanded={expanded}
                        onCheck={(selected) => setSelectedLayoutElements(selected)}
                        onExpand={(expanded) => setExpanded(expanded)}
                    />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );

    const renderExcelDownloadTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("Seçili Donatıları Excel'e Aktar")}
        </Tooltip>
    );

    const renderShowDetailedTableTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("Detaylı Tabloyu Göster")}
        </Tooltip>
    );

    const renderHideDetailedTableTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("Detaylı Tabloyu Gizle")}
        </Tooltip>
    );

    return (
        <Container className='border border-top-0 p-3' fluid style={{ backgroundColor: 'white' }}>
            <h3 className="text-center d-block d-md-none">{t("Donatı Bilgileri")}</h3>
            {dialogOpen &&
                <Dialog
                    maxWidth="xs"
                    fullWidth={true}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}>
                    <DialogContent>
                        {dialogContent}
                    </DialogContent>
                </Dialog>}
            <Row>
                <Col lg={3} md={4} xs={12}>
                    <h5 className="text-center mb-3">{t("Filtreler")} </h5>
                    {filters}
                </Col>

                <Col lg={9} md={8} xs={12}>
                    <Row>
                        <Col xs={12}>
                            <Container className="border shadow-sm mb-3">
                                <h5 className="text-center py-3">{t("Teorik Ağırlıklar (kg)")} <InfoNote content={theoreticalWeightInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /></h5>
                                <WeightTable data={weights} />
                            </Container>
                        </Col>
                        <Col xs={12} >
                            <Container className={`border shadow-sm ${showDetailedTable ? "pb-3" : ""}`} style={{ "display": "flex", "flexDirection": "column", "maxHeight": showDetailedTable ? "" : "100vh" }}>
                                <Row>
                                    <Col>
                                    </Col>
                                    <Col>
                                        <h5 className="text-center py-3">{t("Donatılar")} <InfoNote content={rebarTableInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /></h5>
                                    </Col>
                                    <Col className='d-flex align-items-center justify-content-end'>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 0, hide: 0 }}
                                            overlay={renderExcelDownloadTooltip}
                                        >
                                            <Button variant="link" onClick={() => exportToExcel({ data: excelData, fileName: "selected_rebars" })}>
                                                <TbTableDown size={24} />
                                            </Button>
                                        </OverlayTrigger>
                                        {showDetailedTable ?
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 0, hide: 0 }}
                                                overlay={renderHideDetailedTableTooltip}
                                            >
                                                <Button variant="link" onClick={() => setShowDetailedTable(!showDetailedTable)}>
                                                    <TbTableOff size={24} />
                                                </Button>
                                            </OverlayTrigger>
                                            :
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 0, hide: 0 }}
                                                overlay={renderShowDetailedTableTooltip}
                                            >
                                                <Button variant="link" onClick={() => setShowDetailedTable(!showDetailedTable)}>
                                                    <TbTable size={24} />
                                                </Button>
                                            </OverlayTrigger>
                                        }
                                    </Col>
                                </Row>

                                {showDetailedTable ?
                                    <DetailedRebarTable data={rebars} /> :
                                    <RebarTable data={rebars} />
                                }
                            </Container>
                        </Col>
                        {
                            longRebarsExist ?
                                <Col xs={12} className="mt-3">
                                    <Container className="border shadow-sm" style={{ "display": "flex", "flexDirection": "column", "maxHeight": "100vh" }}>
                                        <h5 className="text-center py-3">{t("Uzun Donatılar")} <InfoNote content={longRebarTableInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /></h5>
                                        <LongRebarTable data={longRebars} bindirmeOrani={props.bindirmeOrani} />
                                    </Container>
                                </Col> : <></>
                        }

                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default RebarInspection;