import React from 'react';
import { useParams } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';

import { PiMicrosoftExcelLogoBold, } from 'react-icons/pi';
import { RiDeleteBin2Line, } from 'react-icons/ri';
import { AiFillWarning, } from 'react-icons/ai';
import { BsThreeDotsVertical, } from 'react-icons/bs';

import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Footer from '../../components/Footer/Footer';

import mainStyles from '../Pages.module.css';
import styles from './ProjectInfo.module.css';

import useProjectInfo from '../../hooks/useProjectInfo/useProjectInfoNew';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';

import { useTranslation } from "react-i18next";

import RebarProjectInfo from './RebarProjectInfo';
import MetalProfileProjectInfoNotSet from './MetalProfileProjectInfoNotSet';
import MetalProfileProjectInfoSet from './MetalProfileProjectInfoSet';

const ProjectInfo = () => {

    const { id } = useParams();

    const {
        isLoading,
        name,
        userType,
        ownProject,
        isDeleting,
        setIsDeleting,
        deleteScreen,
        setDeleteScreen,
        showDeletedToast,
        setShowDeletedToast,
        showErrorToast,
        setShowErrorToast,

        projectType,
        userSetProfileInfo,
        profileInfo,
        setProfileInfo,

        pieces,
        setPieces,

        projectUser,
        projectCreationTime,
        projectUploadedFileType,
        projectActivated,
        projectTheoreticalWeight,
        projectTheoreticalLength,
        
        priceCalculationMethod,
        unitPrice,
        unitDiscount,
        unitPriceType,

        purchasable,
        warningMessage,
        cps,
        activeTab,
        setActiveTab,

        diameters,
        layoutPlan,
        nodes,
        donatiDict,
        rebarListDict,
        logLength,
        floorAvailable,
        explanations,
        bindirmeOrani,

        handleDeleteProject,
        handleDownloadProject,
    } = useProjectInfo(id);

    const { i18n, t } = useTranslation();

    const pname = name === "Ornek-Proje" ? t("Ornek-Proje") : name;

    document.title = pname

    let projectInfoPageBody;

    if (projectType === "rebar") {
        projectInfoPageBody = (
            <RebarProjectInfo
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                id={id}
                name={name}
                projectCreationTime={projectCreationTime}
                projectTheoreticalWeight={projectTheoreticalWeight}
                projectTheoreticalLength={projectTheoreticalLength}
                projectUser={projectUser}
                projectUploadedFileType={projectUploadedFileType}
                logLength={logLength}
                bindirmeOrani={bindirmeOrani}
                floorAvailable={floorAvailable}
                projectActivated={projectActivated}
                priceCalculationMethod={priceCalculationMethod}
                unitPrice={unitPrice}
                unitDiscount={unitDiscount}
                unitPriceType={unitPriceType}
                purchasable={purchasable}
                userType={userType}
                ownProject={ownProject}
                nodes={nodes}
                donatiDict={donatiDict}
                rebarListDict={rebarListDict}
                cps={cps}
                diameters={diameters}
                layoutPlan={layoutPlan}
                explanations={explanations}
            />
        )
    }
    else if (projectType === "metal_profile" && !userSetProfileInfo) {
        projectInfoPageBody = (
            <MetalProfileProjectInfoNotSet
                id={id}
                name={name}
                profileInfo={profileInfo}
            />
        )
    }
    else if (projectType === "metal_profile" && userSetProfileInfo) {
        projectInfoPageBody = (
            <MetalProfileProjectInfoSet
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                id={id}
                name={name}
                profileInfo={profileInfo}
                setProfileInfo={setProfileInfo}
                pieces={pieces}
                projectCreationTime={projectCreationTime}
                projectTheoreticalWeight={projectTheoreticalWeight}
                projectTheoreticalLength={projectTheoreticalLength}
                projectUser={projectUser}
                projectUploadedFileType={projectUploadedFileType}
                projectActivated={projectActivated}
                priceCalculationMethod={priceCalculationMethod}
                unitPrice={unitPrice}
                unitDiscount={unitDiscount}
                unitPriceType={unitPriceType}
                purchasable={purchasable}
                userType={userType}
                ownProject={ownProject}
                cps={cps}
            />
        )
    }

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar projectId={id} />
            <div className={mainStyles.content}>
                {deleteScreen && (
                    <Dialog
                        maxWidth="xs"
                        fullWidth={true}
                        open={deleteScreen}
                        onClose={() => setDeleteScreen(false)}>
                        <DialogTitle>
                            {t("Projeyi silmek istediğinizden emin misiniz?")}
                        </DialogTitle>
                        <DialogContent>
                            {ownProject ?
                                <p>{t("Proje, kesim planlarıyla birlikte silinecek. Bu işlem geri alınamaz.")}</p>
                                :
                                <p style={{ color: "red" }}>{t("projectinfo_silme_uyarisi1")}<span style={{ fontWeight: "bold" }}>{projectUser}</span>{t("projectinfo_silme_uyarisi2")}</p>
                            }

                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="success"
                                onClick={() => {
                                    setDeleteScreen(false)
                                }}>
                                {t("Hayır")}
                            </Button>
                            <Button
                                variant="danger"
                                onClick={() => {
                                    setDeleteScreen(false)
                                    setIsDeleting(true)
                                    handleDeleteProject()
                                }}>
                                {t("Evet")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}

                {isDeleting && (
                    <Backdrop
                        sx={{
                            color: '#fff',
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        open={isDeleting}
                    >
                        <p className="mt-2">{t("Proje siliniyor.")}</p>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}

                {isLoading ? LoadingContainer :
                    <Container fluid className='p-2'>
                        {!ownProject && <div className='text-danger text-center'><span className='fw-bold'>{t("DİKKAT")}</span>: {t("projectinfo_admin_uyarisi1")}<span className='fw-bold'>{projectUser}</span>{t("projectinfo_admin_uyarisi2")}</div>}
                        <Card className={styles.pageHeaderCard}>
                            <Card.Body className={styles.cardBody}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {ownProject &&
                                        <>
                                            <a href={`/${i18n.language}/projects`}><h5 className={styles.projelerim}>{t("Projelerim")}</h5></a>
                                            <h4 className={styles.mySeparator}>&gt;</h4>
                                        </>
                                    }
                                    <h1 className={styles.pageHeader}>{pname}</h1>
                                </div>

                                <div className='d-none d-sm-flex justify-content-center'>
                                    <Dropdown drop='down-centered'>
                                        <Dropdown.Toggle id="dropdown-basic" style={{ "backgroundColor": "transparent", "border": "transparent", "color": "transparent" }}>
                                            <PiMicrosoftExcelLogoBold style={{ "color": "black", "fontSize": "2rem" }} />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleDownloadProject()}>{t("Excel Dosyasını İndir")}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    {purchasable || [4, 5].includes(userType) ?
                                        <Dropdown drop='down-centered'>
                                            <Dropdown.Toggle id="dropdown-basic" style={{ "backgroundColor": "transparent", "border": "transparent", "color": "transparent" }}>
                                                <RiDeleteBin2Line style={{ "color": "black", "fontSize": "2rem" }} />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => setDeleteScreen(true)}>{t("Projeyi Sil")}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> : <></>}

                                </div>

                                <div className='d-flex justify-content-center d-sm-none'>
                                    <Dropdown drop='down-centered'>
                                        <Dropdown.Toggle id="dropdown-basic" style={{ "backgroundColor": "transparent", "border": "transparent", "color": "transparent" }}>
                                            <BsThreeDotsVertical style={{ "color": "black", "fontSize": "2rem" }} />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleDownloadProject()}>{t("Excel Dosyasını İndir")}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setDeleteScreen(true)}>{t("Projeyi Sil")}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                            </Card.Body>
                        </Card>
                        {warningMessage.show ?
                            <Container fluid className='bg-white'>
                                <Row className='mb-3 p-2 border border-danger rounded '>
                                    <Col sm="1" className='d-none d-sm-flex justify-content-center align-items-center'><AiFillWarning style={{ "fontSize": "4rem", "color": "red" }} /></Col>
                                    <Col sm="11" style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ fontWeight: 600, fontSize: '13pt', display: 'flex', alignItems: 'center', minHeight: '50px' }}>
                                            {warningMessage.message}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            : <></>}

                        {projectInfoPageBody}

                    </Container>
                }
            </div>
            <Toast
                onClose={() => setShowDeletedToast(false)}
                show={showDeletedToast}
                bg="success"
                delay={3000}
                autohide
                style={{
                    position: 'fixed',
                    bottom: 20,
                    left: 20,
                    zIndex: 9999,
                }}
            >
                <Toast.Header>
                    <strong className="me-auto">{t("Bilgi")}</strong>
                </Toast.Header>
                <Toast.Body style={{ color: 'white' }}>{t("Kesim planı başarıyla silindi.")}</Toast.Body>
            </Toast>
            <Toast
                onClose={() => setShowErrorToast(false)}
                show={showErrorToast}
                bg="danger"
                delay={3000}
                autohide
                style={{
                    position: 'fixed',
                    bottom: 20,
                    left: 20,
                    zIndex: 9999,
                }}
            >
                <Toast.Header>
                    <strong className="me-auto">{t("Hata")}</strong>
                </Toast.Header>
                <Toast.Body style={{ color: 'white' }}>{t("Proje silinemedi.")}</Toast.Body>
            </Toast>
            <Footer />
        </div >
    );
};

export default ProjectInfo;
