import { useState, useEffect } from 'react';

const useRebarInspection = (props) => {

    const [rebars, setRebars] = useState([]);
    const [longRebars, setLongRebars] = useState([]);
    const [weights, setWeights] = useState([{}])
    const [selectedLayoutElements, setSelectedLayoutElements] = useState(Object.keys(props.rebarListDict));

    const [selectedExplanations, setSelectedExplanations] = useState([])
    const [selectedDiameters, setSelectedDiameters] = useState([]);
    const [lengthInterval, setLengthInterval] = useState(
        [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]
    );

    let floorAvailable = "kat" in Object.values(props.donatiDict)[0]

    const calculateWeight = (diameter, length) => {
        const density = 7.85
        const radius = diameter / 2 * 1e-1
        const A = Math.PI * radius ** 2
        const V = A * length
        const mass = V * density
        return mass * 1e-3
    }

    const createTableRows = (rawRebars) => {
        let weights = {}
        let rebars
        let longRebars = new Set()

        rebars = rawRebars.map((rawRebar, i) => {

            let [diameter, length] = rawRebar.split('-').map(Number);
            let number = Number(props.donatiDict[rawRebar]["adet"]);

            if (weights[diameter]) {
                weights[diameter] += calculateWeight(Number(diameter), Number(length) * number)
            }
            else {
                weights[diameter] = calculateWeight(Number(diameter), Number(length) * number)
            }
            let result = {
                diameter: diameter,
                length: length,
                number: number,
                poz: props.donatiDict[rawRebar]["poz"],
            }

            if (floorAvailable) {
                result = {
                    ...result,
                    floor: props.donatiDict[rawRebar]["kat"].trim(),
                    element: props.donatiDict[rawRebar]["yapiElemani"].trim(),
                    elementCode: props.donatiDict[rawRebar]["yapiElemaniKodu"].trim(),
                    explanation: props.donatiDict[rawRebar]["aciklama"].trim(),
                    code: props.donatiDict[rawRebar]["donatiKodu"]
                }
            }
            return result
        })

        // long rebars:
        for (let i = 0; i < rawRebars.length; i++) {
            let rawRebar = rawRebars[i]
            let info = props.donatiDict[rawRebar]
            let [diameter, length] = rawRebar.split('-').map(Number);
            if ("kesik" in info) {
                let longRebar = {
                    "diameter": diameter,
                    "totalLength": info.toplamUzunluk,
                    "number": Number(info.adet),
                    "floor": info.kat,
                    "element": info.yapiElemani,
                    "elementCode": info.yapiElemaniKodu,
                    "explanation": info.aciklama,
                    "rebarCodes": info.kesikParcaKodlari,
                }
                longRebars.add(JSON.stringify(longRebar))
            }
            else {
                continue
            }
        }
        longRebars = Array.from(longRebars)
        longRebars = longRebars.map((item) => JSON.parse(item))
        // longRebars.sort((a,b) => a.elementCode.localeCompare(b.elementCode))

        for (let i = 0; i < rebars.length; i++) {
            rebars[i]["id"] = i + 1
        }

        return [rebars, [weights], longRebars]
    }

    function layoutFilter(unfilteredRebars, selectedLayoutElements) {
        if (selectedLayoutElements.length === 0){
            return Object.values(unfilteredRebars).flat();
        }
        return selectedLayoutElements.reduce((acc, curr) => {
            return acc.concat(unfilteredRebars[`${curr}`]);
        }, []);
    }

    function explanationFilter(unfilteredRebars, selectedExplanations) {
        if (selectedExplanations.length === 0){
            return unfilteredRebars;
        }

        return unfilteredRebars.filter((rebar) => {
            return selectedExplanations.includes(props.donatiDict[rebar]["aciklama"].trim())
        })
    }

    function diameterFilter(unfilteredRebars, selectedDiameters) {
        if (selectedDiameters.length === 0){
            return unfilteredRebars;
        }

        return unfilteredRebars.filter((rebar) => {
            const diameter = parseInt(rebar.split("-")[0]);
            return selectedDiameters.includes(diameter);
        })
    }

    function  lengthIntervalFilter(unfilteredRebars, lengthInterval) {
        let lowerBound = lengthInterval[0];
        let upperBound = lengthInterval[1];

        if (lowerBound === "") {
            lowerBound = Number.MIN_SAFE_INTEGER;
        }
        if (upperBound === "") {
            upperBound = Number.MAX_SAFE_INTEGER;
        }

        return unfilteredRebars.filter((rebar) => {
            let length = parseInt(rebar.split("-")[1]);
            return (length >= lowerBound && length <= upperBound);
        })
    }

    useEffect(() => {
        const layoutFilteredRebars = layoutFilter(
            props.rebarListDict,
            selectedLayoutElements);
        const explanationFilteredRebars = explanationFilter(
            layoutFilteredRebars,
            selectedExplanations);
        const diameterFilteredRebars = diameterFilter(
            explanationFilteredRebars,
            selectedDiameters);
        const lengthFilteredRebars = lengthIntervalFilter(
            diameterFilteredRebars,
            lengthInterval);

        const [rebars_, weights_, longRebars_] = createTableRows(lengthFilteredRebars)

        setRebars(rebars_)
        setWeights(weights_)
        setLongRebars(longRebars_)
    }, [
        selectedLayoutElements,
        selectedExplanations,
        selectedDiameters,
        lengthInterval
    ]);

    return {
        rebars,
        weights,
        longRebars, // obsolete
        selectedLayoutElements,
        setSelectedLayoutElements,
        setSelectedExplanations,
        setSelectedDiameters,
        lengthInterval,
        setLengthInterval
    }
}

export default useRebarInspection;