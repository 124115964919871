import { useEffect, useState } from 'react';
import { createProject } from '../../services/createProjectService';
import { getUserData } from '../../services/userService';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const useProjectCreation = () => {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const [name, setName] = useState("");

    const [projectType, setProjectType] = useState("select");

    const [projectUploadedFileType, setProjectUploadedFileType] = useState("select");

    const [uploadedFile, setUploadedFile] = useState([]);

    const [logLength, setLogLength] = useState(1200);
    const [bindirmeOrani, setBindirmeOrani] = useState(1.0)
    const [errorToast, setErrorToast] = useState({
        show: false,
        message: ''
    })
    const [isLoading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            let user_data
            try{
                user_data = await getUserData()
            }
            catch{
                // getUserError
            }
            if (user_data.user_type === 3 && !user_data.delegation_active) {
                navigate(`/${i18n.language}/delegation-contract`)
            }
            const user_type = user_data.user_type
            if (user_type === 0) {
                window.location = `/${i18n.language}/inactive-account`
            }
            else {
                setPageLoading(false);
            }
        }
        fetchData()
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();

        let uploadedFileTypeCode;

        if(projectType === "rebar"){
            if(projectUploadedFileType === "rebar_ide_txt"){
                uploadedFileTypeCode = 1;
            }
            else if(projectUploadedFileType === "rebar_sta_xlsx"){
                uploadedFileTypeCode = 2;
            }
            else if(projectUploadedFileType === "rebar_custom_xlsx"){
                uploadedFileTypeCode = 3;
            }
            else if(projectUploadedFileType === "com_metraj_xlsx"){
                uploadedFileTypeCode = 4;
            }
        }
        else if(projectType === "metal_profile"){
            if(projectUploadedFileType === "profile_ide_txt"){
                uploadedFileTypeCode = "ide";
            }
            else if(projectUploadedFileType === "profile_sta_xlsx"){
                uploadedFileTypeCode = "sta";
            }
            else if(projectUploadedFileType === "profile_xsteel_xlsx"){
                uploadedFileTypeCode = "xsteel";
            }
        }

        try {
            setLoading(true)
            const response = await createProject(
                projectType, 
                name,
                uploadedFile,
                uploadedFileTypeCode,
                logLength,
                bindirmeOrani
            )
            if (response.status !== 200) {
                setLoading(false)
                const data = await response.json()
                const errorDetail = data.detail
                const errorCode = data.hataKodu ? `(${t("Hata Kodu")} : ${data.hataKodu})` : ""
                setErrorToast({
                    show: true,
                    message: `${errorDetail} ${errorCode}`,
                })
            }
            else {
                const data = await response.json()
                const id = data.id
                window.location = `/${i18n.language}/project-info/${id}`
            }
        }
        catch{
            var props = {
                error_code: "KRC76A",
                detail: t("Sistem Hatası. Proje oluşturulamadı. Sistem yöneticisi ile irtibata geçin."),
            }
            
            navigate(`/error`, { state: { props: props } })
        }

    }

    return {
        setName,

        projectType,
        setProjectType,

        projectUploadedFileType,
        setProjectUploadedFileType,

        uploadedFile,
        setUploadedFile,

        setLogLength,
        setBindirmeOrani,
        errorToast,
        setErrorToast,
        isLoading,

        handleSubmit,
        pageLoading,
    }
}

export default useProjectCreation;