import apiClient from "./apiClient";

export const createProject = async (
    project_type,
    name,
    file,
    uploadedFileType,
    logLength,
    bindirmeOrani
) => {
    const url = `${process.env.REACT_APP_API_URL}/api/upload/`;

    let formData = new FormData();
    formData.append('project_type', project_type);
    formData.append('name', name);
    // Assuming 'file' is an array of files
    file.forEach((f, index) => {
        formData.append(`file_${index}`, f);
    });
    formData.append('uploaded_file_type', uploadedFileType);
    formData.append('log_length', Number(logLength));
    formData.append('overlap_ratio', Number(bindirmeOrani));

    const response = await apiClient(url, {
        method: 'POST',
        body: formData,
    })

    return response;

}